import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import auth from './auth'
import { i18n } from './i18n'

import * as Amplify from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";

Amplify.Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'OmsApiUrl',
        endpoint: process.env.VUE_APP_API_URL,
      },
      {
        name: 'CarbonFootPrintApi',
        endpoint: process.env.VUE_APP_CARBON_FOOT_PRINT_API_URL
      }
    ]
  }
})

Vue.use(auth)

Vue.use(AmplifyPlugin, Amplify)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
  vuetify,
  i18n,
}).$mount('#app')
