import Vue from "vue";
import VueI18n from "vue-i18n";
import moment from 'moment';


Vue.use(VueI18n);
// set locale with the navigator language
//let locale = navigator.language
let locale = navigator.language.substr(0, 2);
// configuration of i18n
moment.locale(locale);
export const i18n = new VueI18n({
  locale,
  fallbackLocale: "en",
});
