import Vue from "vue";
import { UsersService } from "@/services/sso-users.service.js";
import { store } from "./store";
import { Utils } from "./mixins/Utils"
import EventBus from './event-bus'

let auth = new Vue({
  data() {
    return {
      ssoService: new UsersService(),
      accessToken: localStorage.getItem("access_token"),
      tokenType: localStorage.getItem('token_type'),
      refreshToken: localStorage.getItem('refresh_token'),
      expiresAt: localStorage.getItem('expires_at'),
      user: localStorage.getItem('user')
    };
  },
  methods: {
    setAccessToken (accessToken) {
      localStorage.setItem('access_token', accessToken)
      this.accessToken = accessToken
    },
    setTokenType (tokenType) {
      localStorage.setItem('token_type', tokenType)
      this.tokenType = tokenType
    },
    setRefreshToken (refreshToken) {
      localStorage.setItem('refresh_token', refreshToken)
      this.refreshToken = refreshToken
    },
    setExpiresAt (expiresIn) {
      let expiresAt = JSON.stringify(expiresIn * 1000 + new Date().getTime());
      localStorage.setItem("expires_at", expiresAt);
      this.expiresAt = expiresAt
    },
    setUser (user) {
      localStorage.setItem("user", JSON.stringify(user))
      this.user = user
    },
    login() {
      window.location.replace(
          process.env.VUE_APP_SSO_API_URL +
          "/oauth2/authorize?client_id=" +
          process.env.VUE_APP_SSO_APP_ID +
          "&scope=offline_access" +
          "&response_type=code&redirect_uri=" +
          process.env.VUE_APP_SSO_REDIRECT_URL
      );
    },
    async logout() {
      try{
        localStorage.removeItem("access_token");
        localStorage.removeItem("token_type");
        localStorage.removeItem("expires_at");
        localStorage.removeItem("user");
        localStorage.removeItem("refresh_token");
        window.location.replace(
          process.env.VUE_APP_SSO_API_URL +
          "/oauth2/logout?client_id=" +
          process.env.VUE_APP_SSO_APP_ID +
          "&post_logout_redirect_uri=" +
          process.env.VUE_APP_SSO_REDIRECT_URL
        );
      }catch(e){
        EventBus.$emit('errors', 'sso')
      }
    },
    async isAuthenticated() {
      if (this.accessToken) {
        try {
          await this.ssoService.validateToken();
          return true;
        } catch (e) {
          return await this.handleRefreshToken();
        }
      }
    },
    async handleRefreshToken() {
      if (this.accessToken && this.refreshToken) {
        try{
          const token = await this.ssoService.requestToken({
            client_id: process.env.VUE_APP_SSO_APP_ID,
            grant_type: "refresh_token",
            refresh_token: this.refreshToken,
            access_token: this.accessToken,
          });

          if (typeof token === "object" || !JSON.parse(token).error) {
            this.setAccessToken(token.access_token)
            this.setExpiresAt(token.expires_in)
            this.setUser(token.userId)
            this.setTokenType(token.token_type)
            await store.dispatch("setFusionAuthToken", token);
            return true;
          }
          return await this.logout()
        } catch(e){
          EventBus.$emit('errors', 'sso')
          return await this.logout();
        }
      } else {
        return await this.logout();
      }
    },
    async handleAuthentication(code) {
      let token
      try {
        // Default Oauth2 Auth
        token = await this.ssoService.requestToken({
          client_id: process.env.VUE_APP_SSO_APP_ID,
          code: code,
          grant_type: 'authorization_code',
          scope: 'offline_access',
          redirect_uri: process.env.VUE_APP_SSO_REDIRECT_URL
        })
      } catch (e) {
        console.log(e)
        EventBus.$emit('errors', 'sso')
        return false
      }

      if (typeof token === "object" || !JSON.parse(token).error) {
        this.setRefreshToken(token.refresh_token)
        this.setAccessToken(token.access_token)
        this.setExpiresAt(token.expires_in)
        this.setUser(token.userId)
        this.setTokenType(token.token_type)

        let myInit = Utils.methods.buildMyInit(null, token.access_token)

        let user
        try {
          user = await this.$Amplify.API.get('OmsApiUrl', '/sso-service/user/' + token.userId, myInit)
        } catch (e) {
          console.log(e)
          return false
        }
        if(user.user.email){
          await store.dispatch("setUserEmail", user.user.email)
        }
        else {
          await store.dispatch("setUserEmail", null)
        }

        if(user.user.username){
          await store.dispatch("setUsername", user.user.username)
        }
        else {
          await store.dispatch("setUsername", null)
        }
        return true
      } else {
        this.logout();
      }
    },
  },
});

export default {
  install: function (Vue) {
    Vue.prototype.$auth = auth;
  },
};
