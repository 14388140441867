<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  /*text-align: center;*/
  text-overflow: ellipsis;
  max-width: 100%;
}
body { text-transform: uppercase }
</style>
