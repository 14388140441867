<template>
    <v-main>
        <keep-alive :include="['PromisesDelivery']">
            <router-view/>
        </keep-alive>
    </v-main>
</template>

<script>
export default {
    name: 'MainView',
}
</script>
