export const Utils = {
  methods: {
    buildMyInit(formData = null, accessToken = null) {
        let init = {}
        init.headers = { Authorization: `Bearer ${accessToken ?? this.$auth.accessToken}` }
        if(formData) {
          init.body = formData
        }
  
        return init
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
      if ((lat1 === lat2) && (lon1 === lon2)) {
          return 0;
      }
      else {
          let radlat1 = Math.PI * lat1/180;
          let radlat2 = Math.PI * lat2/180;
          let theta = lon1-lon2;
          let radtheta = Math.PI * theta/180;
          let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
          if (dist > 1) {
              dist = 1;
          }
          dist = Math.acos(dist);
          dist = dist * 180/Math.PI;
          dist = dist * 60 * 1.1515;
          return dist * 1.609344;
      }
    }
  }
}
