export default {
    setIsDark (context, payload) {
        context.commit('setIsDark', payload)
    },
    setUserEmail (context, payload) {
        context.commit('setUserEmail', payload)
    },
    setUsername (context, payload) {
        context.commit('setUsername', payload)
    },
    setFusionAuthToken (context, payload) {
        context.commit('setFusionAuthToken', payload)
    }
}
