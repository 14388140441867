<template>
    <v-app>
        <main-view/>

    </v-app>
</template>

<script>
import MainView from '../components/MainView';
import moment from 'moment'

export default {
    name: 'Index',
    data () {
        return {
            snackbar: {
                display: false,
                text: '',
                color: ''
            },
            loading: true
        }
    },
    components: {
        'main-view': MainView,
    },
    async created() {
        if(this.$route.query.locale){
            moment.locale(this.$route.query.locale)
            this.$i18n.locale = this.$route.query.locale
        }
        if(await this.$auth.isAuthenticated()) {
            this.$router.push('')
            this.loading = false
        } else {
            if (this.$router.currentRoute.query.code) {
                let authentification = await this.$auth.handleAuthentication(this.$router.currentRoute.query.code)
                if(authentification) {
                    this.$router.push('')
                    this.loading = false
                } else {
                    this.$auth.logout()
                }
            } else {
                this.$auth.login()
            }
        }
    }
}
</script>
