const enLang = require('./en.json');
const frLang = require('./fr.json');

module.exports = (lang) => {
  if (lang === 'en') {
    return enLang;
  } if (lang === 'fr') {
    return frLang;
  }
  return enLang;
};
