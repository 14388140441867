import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@/scss/overrides.sass'

import IconStore from "../components/icons/IconStore"
import IconUser from "../components/icons/IconUser"
import IconLogout from "../components/icons/IconLogout"
import IconLogoOctipas from "../components/icons/IconLogoOctipas"
import IconConfiguration from "../components/icons/IconConfiguration"
import IconModule from "../components/icons/IconModule"
import IconService from "../components/icons/IconService"
import IconTag from "../components/icons/IconTag"
import IconLocation from "../components/icons/IconLocation"
import IconDelivery from "../components/icons/IconDelivery"
import IconPhone from "../components/icons/IconPhone"
import IconWeb from "../components/icons/IconWeb"

Vue.use(Vuetify);

const opts = {
    icons: {
        iconfont: 'mdi',
        values: {
            delivery: {
                component: IconDelivery
            },
            store: {
                component: IconStore
            },
            user: {
                component: IconUser
            },
            logout: {
                component: IconLogout
            },
            logo: {
                component: IconLogoOctipas
            },
            configurations: {
                component: IconConfiguration
            },
            modules: {
                component: IconModule
            },
            services: {
                component: IconService
            },
            tags: {
                component: IconTag
            },
            locations: {
                component: IconLocation
            },
            phone: {
                component: IconPhone
            },
            web: {
                component: IconWeb
            }
        }
    },
    theme: {
        themes: {
            light: {
                primary: '#cca257',
            },
            dark: {
                primary: '#cca257',
                secondary: "#cca257"
            }
        }
    }
}

export default new Vuetify(opts)