export default {
    setIsDark (state, payload) {
        state.isDark = payload
    },
    setUserEmail (state, payload) {
        state.userEmail = payload
    },
    setUsername (state, payload) {
        state.username = payload
    },
    setFusionAuthToken (state, payload) {
        state.fusionAuthToken = payload
    }
}
