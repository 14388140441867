import Vue from 'vue'
import Router from 'vue-router'

import Index from '../views/Index'

import PromisesDelivery from '../views/PromisesDelivery/PromisesDelivery'


Vue.use(Router)

const routes = [
  {
    path: '/',
    component: Index,
    children: [
      {
        path: '/',
        name: 'promisesDelivery',
        component: PromisesDelivery,
        props: true
      }
    ]
  }
]

const router = new Router({
  routes,
  base: process.env.BASE_URL,
  mode: 'history'
})

export default router
