<template>
  <v-snackbar
    v-model="display"
    :color="color"
    bottom
    right
    multi-line
    :timeout="6000"
  >
    {{ text }}
  </v-snackbar>
</template>

<script>

import EventBus from '../event-bus';

export default {
  name: 'Snackbar',
  data() {
    return {
      display: false,
      color: 'red',
      text: null,
    };
  },
  mounted() {
    EventBus.$on('snackbar', (payload) => {
      this.display = true;
      this.text = payload.text;
      this.color = payload.color;
    });
  },
};
</script>

<style scoped lang="scss">

</style>
