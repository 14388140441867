const qs = require('querystring')

export class ApiCoreService {
  /**
  * Make the request according to the typeRequest parameter
  * Parameters query Params and body are null by default because they are optional during a query.
  * @param typeRequest (String) : GET, POST, PUT, DELETE, etc...
  * @param url (String) : the URL to access with the request
  * @param queryParams (Object) : parameters in the query (e.g. : "?firstname=Jean&lastname=Bon" will be { firstname: 'Jean', lastname: 'Bon' })
  * @param apikey
  * @param body (Object) : parameters in the body
  * @param isBodyFormUrlEncoded
  * @returns {Promise<any>}
  */
  async requestFusionAuth(typeRequest, url, queryParams = null, apikey = null, body = null, isBodyFormUrlEncoded = false) {
    try {
      // Create a new URL with the url parameters, and the origin url of the page
      let newUrl = new URL(url, process.env.VUE_APP_SSO_API_URL)
      // If query parameters are sent, we create a new URLSearchParams and give it to newUrl
      if (queryParams !== null) {
        newUrl.search = new URLSearchParams(queryParams)
      }
      // Define the default header
      let headers = new Headers()
      headers.append('Accept', 'application/json')
      if (body !== null) {
        if (isBodyFormUrlEncoded) {
          headers.append('Content-Type', 'application/x-www-form-urlencoded')
          body = qs.stringify(body)
        } else {
          headers.append('Content-Type', 'application/json')
          body = JSON.stringify(body)
        }
      }

      if (apikey !== null) {
        headers.append('Authorization', apikey)
      }
      return new Promise((resolve, reject) => {
        // Basic initialization of fetch
        let initFetch = {
          method: typeRequest,
          headers: headers,
          body: body || null,
          credentials: 'include'
        }

        /**
         * Using fetch to launch an ajax request
         * @param newUrl is the url called
         * @param initFetch is the basic initialization of fetch
         */
        fetch(newUrl, initFetch)
          .then(response => {
            if (typeRequest === 'GET' || typeRequest === 'POST') {
              if (response.ok) {
                response.json()
                .then(jsonResponse => {
                  resolve(jsonResponse)
                })
                .catch(()=>{resolve({})})
              } else {
                response.text().then(textResponse => {
                  textResponse ? reject(textResponse) : reject(response)
                })
              }
            } else if (typeRequest === 'PUT') {
              if (response.ok) {
                response.json().then(jsonResponse => {
                  resolve(jsonResponse)
                })
              } else {
                response.text().then(textResponse => {
                  reject(textResponse)
                })
              }
            } else if (typeRequest === 'DELETE') {
              let newResponse = {
                deleted: true
              }
              if (response.ok) {
                resolve(newResponse)
              } else {
                newResponse.deleted = false
                newResponse.errors = response
                reject(newResponse)
              }
            } else {
              response
                .text()
                // .then(async textResponse => {})
                .catch(error => {
                  reject(error.message)
                })
            }
            // Catch only network problems
          })
          .catch(error => {
            reject(error)
          })
      })
    } catch (error) {
      console.error(error)
    }
  }
}
