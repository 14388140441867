import { ApiCoreService } from "./core/api-core.service";

export class UsersService {
  constructor() {
    this.apiService = new ApiCoreService();
  }

  /**
   * Call FusionAuth Server to request an access token to the application (login the user)
   * More information:
   * https://fusionauth.io/docs/v1/tech/apis
   *
   * @returns { json } return token and clientId
   */
  requestToken(code) {
    return this.apiService
      .requestFusionAuth("POST", "oauth2/token", null, null, code, true)
      .then((response) => {
        return response;
      });
  }

  /**
   * Call FusionAuth Server to logout a user
   * More information:
   * https://fusionauth.io/docs/v1/tech/apis
   *
   * @returns { json } return token and clientId
   */
  async logout (clientId) {
    return await this.apiService.requestFusionAuth(
      'GET',
      'oauth2/logout',
      `client_id=${clientId}`,
      clientId,
      null
    )
  }

  /**
   * Call FusionAuth Server to confirm if the JWT is good and not expired
   * More information:
   * https://fusionauth.io/docs/v1/tech/apis
   */
  async validateToken() {
    return await this.apiService.requestFusionAuth(
      "GET",
      "api/jwt/validate",
      null,
      "JWT " + localStorage.getItem("access_token"),
      null
    );
  }

  /**
   * Call FusionAuth Server to get a single user
   * The list of user can only be users from the same group as the user who asked the request
   * More information:
   * https://fusionauth.io/docs/v1/tech/apis
   */
  async getUserById(id) {
    return this.apiService.requestFusionAuth(
      "GET",
      `api/user/${id}`,
      null,
      process.env.VUE_APP_SSO_API_KEY,
      null
    );
  }
}
